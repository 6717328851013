import portugueseMessages from "@henriko/ra-language-portuguese";

const customPortugueseMessages = {
    ...portugueseMessages,

    pos: {
        search: 'Pesquisar',
        configuration: 'Configuração',
        settings: 'Configurações',
        user_profile: 'Editar utilizador',
        language: 'Linguagem',
        theme: {
            name: 'Tema',
            light: 'Claro',
            dark: 'Escuro',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            month_history: '30 Day Revenue History',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            all_reviews: 'See all reviews',
            new_customers: 'New Customers',
            all_customers: 'See all customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Bem-vindo ao administrador do restaurante Sabores CV',
                subtitle:
                    "Este é o administrador dos restaurantes. Sinta-se à vontade para explorar e modificar os dados - eles são locais no seu computador e serão redefinidos sempre que você recarregar.",
                ra_button: 'SCV site',
                demo_button: 'Meu restaurante',
            },
        },
        menu: {
            sales: 'Sales',
            catalog: 'Catalog',
            customers: 'Customers',
        },
        restaurantDashboard:{
            restaurant: 'Restaurante',
            incomeInTheLastMonth: 'Ganhos no último mês',
            unconfirmedBooking: 'Reserva por confirmar ',
            reviewsLast7Days: 'Revisões nos últimos 7 dias',
            charityContribution: 'Contribuição no último mês',
            monthHistory: 'Histórico de Receita 30 dias',
            contributionHistoryChart: 'Contribuição para solidariedade no último mês',
            averageMonthlyRating: 'Média mensal de revisões ',
            booking30days: 'Reservas (30 dias)',
        },

    },
    menu: {
        exitRestaurant: 'Meus Restaurantes',
        dashboard: 'Painel de controle',
        categories: 'Categorias',
        recipes: 'Receitas',
        restaurants: 'Restaurantes',
        faq: 'FAQ',
        contacts: 'Contactos',
        support: 'Suporte',
        restaurantProducts: 'Produtos',
        menu: 'Menu',
        reservations: 'Reservas',
        profile: 'Perfil',
        settings: 'Configurações',
        'take-away-request': 'Take-away',

    },
    resources: {
        categories: {
            name: 'Categoria |||| Categorias',
            fields: {
                products: 'Produtos',
            },
        },
        recipes: {
            name: 'Recipe |||| Recipes',
            fields: {
                name: 'Nome',
                description: 'Descrição',
                prepTime: 'Tempo de preparação(min)',
                cookTime: 'Tempo de cozinhar(min)',
                servings: 'Porções',
                status: 'Estado',
                difficulty: 'Dificuldade',
                cost: 'Custo',
                keywords: 'Palavra-chave',
                assets: 'Assets',
                ingredients: 'Ingredientes',
                instructions: 'Instruções',
                source: 'Fonte',
                private: 'Privado',
                image: 'Imagem',
                video: 'Vídeo',
                quantity: 'Quantidade',
                categories: 'categories',
                product:{
                    name: 'Producto',
                },
                unit:{
                    name: 'Unidade',
                },
                difficulty_gte:'Dificuldade >=',
                difficulty_lte:'Dificuldade <=',
                servings_gte:'Porções >=',
                servings_lte:'Porções <=',
                cookTime_gte:'Tempo de cozinhar >=',
                cookTime_lte:'Tempo de cozinhar <=',
                cost_gte:'Custo >=',
                cost_lte:'Custo <=',
                prepTime_gte:'Tempo de preparação >=',
                prepTime_lte:'Tempo de preparação <=',
                created_at_gte:'Criado em >=',
                created_at_lte:'Criado em <=',
            },
            tabs: {
                assets: 'Assets',
                details: 'Detalhes',
                ingredients: 'Ingredientes',
                instructions: 'Instruções',
            },
            link_recipe: {
                title: "Receitas",
                subtitle: "Receita associada do diretório SCV",
                recipe: "Receita",
                info: "informações",
                recipes_linked: "Receitas vinculadas.",
                error_message: "Ocorreu um erro técnico ao vincular as receitas. Por favor tente mais tarde.",
                warning: "aviso",
                title_unlinked:'Desvincular Receitas',
                subtitle_unlinked:'Tem certeza de que deseja desvincular as receitas do seu restaurante?',
                recipes_unlinked: "Desvincular Receitas.",
                error_message_unlink: "Ocorreu um erro técnico ao desvincular as receitas. Por favor tente mais tarde.",
            }
        },
        'restaurant-products':{
            name: 'Produto |||| Produtos',
            fields: {
                name:'Nome',
                description:'Descricação',
                price:'Preço',
                tax:'Iva',
                price_gte:'Preço >=',
                price_lte:'Preço <=',
                tax_gte:'Iva >=',
                tax_lte:'Iva <=',
                created_at_gte:'Criado em >=',
                created_at_lte:'Criado em <=',
            }
        },

        'restaurant-menus':{
            name: 'Restaurante Menus',
            fields: {
                name:'Nome',
                description:'Descricação',
                price:'Preço',
                tax:'Iva',
                servings:'Porções',
                endDate:'Data ínicio',
                startDate:'Data termino',
                totalPrice:'Preço total',
                startDate_gte:'Data ínicio >=',
                startDate_lte:'Data ínicio >=',
                endDate_gte:'Data termino>=',
                endDate_lte:'Data termino <=',
                created_at_gte:'Criado em >=',
                created_at_lte:'Criado em <=',
            }
        },

        reservations:{
            name: 'Reserva |||| Reservas',
            fields: {
                name:'Nome',
                date:'Data',
                seats:'Lugares',
                menus:'Menus',
                extraProducts:'Productos Extra',
                author:'Autor',
                date_gte:'Data >=',
                date_lte:'Data <=',
                seats_gte:'Lugares >=',
                seats_lte:'Lugares <=',
                created_at_gte:'Criado em >=',
                created_at_lte:'Criado em <=',
            }
        },

        'take-away-requests':{
            name: 'Take-away',
            fields: {
                name:'Nome',
                description:'Descricação',
                price:'Preço',
                tax:'Iva',
                servings:'Porções',
                date:'Data',
                totalPrice:'Preço Total',
                other_products:'Others Products',
                charity:'Associações',
                entries:'Ementas',
                date_gte:'Data >=',
                date_lte:'Data <=',
                created_at_gte:'Criado em >=',
                created_at_lte:'Criado em <=',

            }
        },
        restaurants: {
            name: 'Restaurant |||| Restaurants',

            fields: {
                name:'Nome',
                description:'Descricação',
                email:'E-mail',
                phone:'Telefone',
                mobile:'Telemóvel',
                website:'Website',
                managers:'Managers',
                slots: 'Aberto em',
                day: 'Dia',
                days: {
                    workingDays: 'Dias da Semana',
                    weekend: 'Fim-de-semana',
                    sunday: 'Domingo',
                    monday: 'Segunda',
                    tuesday: 'Terça',
                    wednesday: 'Quarta',
                    thursday: 'Quinta',
                    friday: 'Sexta',
                    saturday: 'Sábado',
                },
                timeSlots: 'Horários',
                timeSlot: {
                    open: 'Abre às',
                    close: 'Fecha às'
                },
                tags:'Tags',
                tag:'Tag',
                address:{
                    address:'Endereço',
                    city:'Cidade',
                    state:'Estado',
                    country:'País',
                    lat:'Latitude',
                    lng:'Longitude',
                },
                social: {
                    facebook: 'Facebook',
                    instagram: 'Instagram',
                    twitter: 'Twitter',
                },
                amenities: {
                    title: "Facilidades",
                    airConditioning: "Ar condicionado",
                    creditCard: "Cartão de crédito/débito",
                    driveThru: "Drive Thru",
                    freeWifi: "Free Wifi",
                    outdoorSeating: "Lugares ao ar livre",
                    parking: "Estacionamento",
                    smokingArea: "Área de fumantes",
                    takeAway: "TakeAway",
                    waiterService: "Serviç de mesa",
                    tv: "Tv",
                    wheelchairAccess: "Acesso para cadeira de rodas",
                    noise: "Ruído",
                    noiseLevel: {
                        none: "Nenhum",
                        low: "Baixo",
                        medium: "Médio",
                        high: "Alto"
                    }
                },


            },

        },
        users: {
            name: 'User |||| Users',

            fields: {
                firstName:'Nome próprio',
                lastName:'Apelido',
                username:'Nome de utilizador',
                email:'Email',
                mobile:'Telemóvel',
                phone:'Telefone',
                address:{
                    address:'Endereço',
                    city:'Cidade',
                    state:'Estado',
                    country:'País',
                    lat:'Latitude',
                    lng:'Longitude',
                },
                social: {
                    facebook: 'Facebook',
                    instagram: 'Instagram',
                    twitter: 'Twitter',
                }
            }

        },
        contacts:{
            name: 'Ajuda |||| Ajudas',
            title: 'Ajuda',
            partners: 'Parceiros',
            contactInformation: 'Information de Contactos',
            sendMessage: 'Enviar mensagem',
            fields: {
                from:'E-mail',
                subject:'Assunto',
                message:'Mensagem',
            }
        },


        reviews: {
            name: 'Review |||| Reviews',
            amount: '1 review |||| %{smart_count} reviews',
            relative_to_poster: 'Review on poster',
            detail: 'Review detail',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating',
            },
            action: {
                accept: 'Accept',
                reject: 'Reject',
            },
            notification: {
                approved_success: 'Review approved',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            },
        },
    },
    dialog: {
        actions: {
            clearLabel: 'Limpar',
            cancelLabel: 'Cancelar',
            okLabel: 'Ok',
        }
    },

    auth: {
        forgot_password: 'Esqueceu-se da palavra-passe',
        reset_password: 'Redefinir a palavra-passe',
        change_password: 'Mudar palavra-passe',
        email: 'E-mail',
        send: 'Enviar',
        password: 'Palavra-passe',
        restaurant_admin: 'Admin Restaurantes ',
        messages: {
            forgot_password: {
                error: {
                    title: 'Esqueceu a Senha',
                    description: 'Ocorreu um erro técnico ao solicitar uma redefinição de senha. Por favor tente mais tarde.'
                },
                success: {
                    title: 'Esqueceu a Senha',
                    description: 'O pedido para redefinir sua senha foi concluída com sucesso. Por favor, verifique o seu email.'
                }
            },
            reset_password: {
                error: {
                    title: 'Redefinir Password',
                    description: 'Ocorreu um erro técnico na redefinição de senha',
                    different_password:'Sua senha e senha de confirmação não coincidem.'
                },
                success: {
                    title: 'Redefinir Password',
                    description: 'A senha foi alterada com sucesso.'
                }
            }
        }
    }

};

export default customPortugueseMessages;
