import englishMessages from 'ra-language-english';

const customEnglishMessages = {
    ...englishMessages,

    pos: {
        search: 'Search',
        configuration: 'Configuration',
        settings: 'Settings',
        user_profile: 'Edit profile',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {

            monthly_revenue: 'Monthly Revenue',
            month_history: '30 Day Revenue History',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            all_reviews: 'See all reviews',
            new_customers: 'New Customers',
            all_customers: 'See all customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to the Sabores CV restaurant admin',
                subtitle:
                    "This is the admin of restaurants. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                ra_button: 'SCV site',
                demo_button: 'My restaurant',
            },
        },
        menu: {
            sales: 'Sales',
            catalog: 'Catalog',
            customers: 'Customers',
        },
        restaurantDashboard: {
            restaurant: 'Restaurant',
            incomeInTheLastMonth: 'Earnings in the last month',
            unconfirmedBooking: 'Unconfirmed booking',
            reviewsLast7Days: 'Reviews in the last 7 days',
            charityContribution: 'Contribution in the Last Month',
            monthHistory: '30 Day Recipe History',
            contributionHistoryChart: '30 Day charity contribution history',
            averageMonthlyRating: 'Monthly average of reviews',
            booking30days: 'Booking (30 days)',
        },
    },
    menu: {
        dashboard: 'Dashboard',
        exitRestaurant: 'My Restaurants',
        categories: 'Categories',
        recipes: 'Recipes',
        restaurants: 'Restaurants',
        faq: 'FAQ',
        contacts: 'Support',
        support: 'Support',
        restaurantProducts: 'Products',
        menu: 'Menu',
        reservations: 'Reservations',
        profile: 'Profile',
        settings: 'Settings',
        'take-away-request': 'Take-away',
    },
    resources: {
        categories: {
            name: 'Category |||| Categories',
            fields: {
                products: 'Products',
            },
        },
        recipes: {
            name: 'Recipe |||| Recipes',
            fields: {
                name: 'Name',
                description: 'Description',
                prepTime: 'PrepTime(min)',
                cookTime: 'CookTime(min)',
                servings: 'Servings',
                status: 'Status',
                difficulty: 'Difficulty',
                cost: 'cost',
                keywords: 'Keywords',
                assets: 'Assets',
                ingredients: 'Ingredients',
                instructions: 'Instructions',
                source: 'Source',
                private: 'Private',
                image: 'Image',
                video: 'Video',
                quantity: 'Quantity',
                categories: 'Categories',
                product: {
                    name: 'Product',
                },
                unit: {
                    name: 'Unit',
                },
                difficulty_gte: 'Difficulty >=',
                difficulty_lte: 'Difficulty <=',
                servings_gte: 'Servings >=',
                servings_lte: 'Servings <=',
                cookTime_gte: 'Cook time >=',
                cookTime_lte: 'Cook time <=',
                cost_gte: 'Cost >=',
                cost_lte: 'Cost <=',
                prepTime_gte: 'Prepare Time >=',
                prepTime_lte: 'Prepare Time <=',
                created_at_gte: 'Create at >=',
                created_at_lte: 'Create at <=',

            },
            tabs: {
                assets: 'Assets',
                details: 'Details',
                ingredients: 'Ingredients',
                instructions: 'Instructions',
            },
            link_recipe: {
                title: "Recipes",
                subtitle: "Associate Recipe from SCV Directory",
                recipe: "Recipe",
                info: "info",
                recipes_linked: "Recipes linked.",
                error_message: "A technical error occurred while linking recipes. Please try later.",
                warning: "warning",
                title_unlinked: 'Unlink Recipes',
                subtitle_unlinked: 'Are you sure you want to unlink the recipes from your restaurant?',
                recipes_unlinked: "Recipes unlinked.",
                error_message_unlink: "A technical error occurred while unlinking recipes. Please try later.",
            }
        },
        'restaurant-products': {
            name: 'Restaurant Products',
            fields: {
                name: 'Name',
                description: 'Description',
                price: 'Price',
                tax: 'Tax',
                price_gte: 'Price >=',
                price_lte: 'Price <=',
                tax_gte: 'Tax >=',
                tax_lte: 'Tax <=',
                created_at_gte: 'Create at >=',
                created_at_lte: 'Create at <=',
            }
        },
        'restaurant-menus': {
            name: 'Restaurant Menus',
            fields: {
                name: 'Name',
                description: 'Description',
                price: 'Price',
                tax: 'Tax',
                servings: 'Servings',
                endDate: 'End Date',
                startDate: 'Start Date',
                totalPrice: 'Total Price',
                startDate_gte: 'Start Date >=',
                startDate_lte: 'Start Date >=',
                endDate_gte: 'End Date >=',
                endDate_lte: 'End Date <=',
                created_at_gte: 'Create at >=',
                created_at_lte: 'Create at <=',
            }
        },
        reservations: {
            name: 'Reservation |||| Reservations',
            fields: {
                date: 'Date',
                seats: 'Seats',
                menus: 'Menus',
                extraProducts: 'Extra Products',
                author: 'Author',
                date_gte: 'Date >=',
                date_lte: 'Date <=',
                seats_gte: 'Seats >=',
                seats_lte: 'Seats <=',
                created_at_gte: 'Create at >=',
                created_at_lte: 'Create at <=',
            }
        },

        'take-away-requests': {
            name: 'Take-away',
            fields: {
                name: 'Name',
                description: 'Description',
                price: 'Price',
                tax: 'Tax',
                servings: 'Servings',
                date: 'Date',
                totalPrice: 'Total Price',
                other_products: 'Outros Produtos',
                charity: 'Charity',
                entries: 'entries',
                'others-products': 'Others Products',
                date_gte: 'Date >=',
                date_lte: 'Date <=',
                created_at_gte: 'Create at >=',
                created_at_lte: 'Create at <=',
            }
        },
        ingredients: {
            product: 'Product',
            unit: 'Unit',
            quantity: 'Quantity',
        },
        instructions: {
            name: 'Name',
            description: 'Description',
            image: 'Image',
            video: 'Video',
            type: 'Type',
        },

        restaurants: {
            name: 'Restaurant |||| Restaurants',
            fields: {
                name: 'Name',
                description: 'Description',
                email: 'Email',
                phone: 'Phone',
                mobile: 'Mobile',
                website: 'Website',
                managers: 'Managers',
                slots: 'Opened at',
                day: 'Day',
                days: {
                    workingDays: 'Working Days',
                    weekend: 'Weekend',
                    sunday: 'Sunday',
                    monday: 'Monday',
                    tuesday: 'Tuesday',
                    wednesday: 'Wednesday',
                    thursday: 'Thursday',
                    friday: 'Friday',
                    saturday: 'Saturday',
                },
                timeSlots: 'Time Slots',
                timeSlot: {
                    open: 'Opens at',
                    close: 'Closes at'
                },
                tags:'Tags',
                tag:'Tag',
                amenities: {
                    title: "Amenities",
                    airConditioning: "Air Conditioning",
                    creditCard: "Credit Card",
                    driveThru: "Drive Thru",
                    freeWifi: "Free Wifi",
                    outdoorSeating: "Outdoor Seating",
                    parking: "Parking",
                    smokingArea: "Smoking Area",
                    takeAway: "TakeAway",
                    waiterService: "Waiter Service",
                    tv: "Tv",
                    wheelchairAccess: "Wheelchair Access",
                    noise: "Noise",
                    noiseLevel: {
                        none: "None",
                        low: "Low",
                        medium: "Medium",
                        high: "High"
                    }
                },
                address: {
                    address: 'Address',
                    city: 'City',
                    state: 'State',
                    country: 'Country',
                    lat: 'Latitude',
                    lng: 'Longitude',
                },
                social: {
                    facebook: 'Facebook',
                    instagram: 'Instagram',
                    twitter: 'Twitter',
                }
            },

        },

        users: {
            name: 'User |||| Users',

            fields: {
                firstName: 'First name',
                lastName: 'Last name',
                username: 'Username',
                email: 'Email',
                mobile: 'Mobile',
                phone: 'Phone',
                address: {
                    address: 'Address',
                    city: 'City',
                    state: 'State',
                    country: 'Country',
                    lat: 'Latitude',
                    lng: 'Longitude',
                },
                social: {
                    facebook: 'Facebook',
                    instagram: 'Instagram',
                    twitter: 'Twitter',
                }
            }

        },

        contacts: {
            name: 'Support |||| Supports',
            title: 'Support',
            partners: 'Partners',
            contactInformation: 'Contact Information',
            sendMessage: 'Send Message',
            fields: {
                from: 'Email',
                subject: 'Subject',
                message: 'Message',
            }
        },

        reviews: {
            name: 'Review |||| Reviews',
            amount: '1 review |||| %{smart_count} reviews',
            relative_to_poster: 'Review on poster',
            detail: 'Review detail',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating',
            },
            action: {
                accept: 'Accept',
                reject: 'Reject',
            },
            notification: {
                approved_success: 'Review approved',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            },
        },
    },
    dialog: {
        actions: {
            clearLabel: 'Clear',
            cancelLabel: 'Cancel',
            okLabel: 'Ok',
        }
    },

    auth: {
        forgot_password: 'Forgot Password',
        reset_password: 'Reset Password',
        change_password: 'Change Password',
        email: 'Email',
        send: 'Send',
        password: 'Password',
        confirm_password: 'Confirm password',
        restaurant_admin: 'Restaurant Admin',
        messages: {
            forgot_password: {
                error: {
                    title: 'Forgot Password',
                    description: 'A technical error occurred while requesting a password reset. Please try later.'
                },
                success: {
                    title: 'Forgot Password',
                    description: 'The request to reset your password has been successfully completed. Please check your inbox.'
                }
            },
            reset_password: {
                error: {
                    title: 'Reset Password',
                    description: 'A technical error occurred while reset password.',
                    different_password:'Your password and confirmation password do not match.'
                },
                success: {
                    title: 'Reset Password',
                    description: 'The password has been changed successfully'
                }
            }
        }
    }

};

export default customEnglishMessages;
