import * as React from "react";
import {Edit} from 'react-admin';
import {useParams} from "react-router-dom";
import RestaurantMenuForm from "./RestaurantMenuForm";


const RestaurantMenuEdit = props => {
    const {id} = useParams();
    
    return (
        <Edit basePath={`/`} resource={`restaurant-menus`} id={id}>
           <RestaurantMenuForm {...props}/>
        </Edit>
    );
};

export default RestaurantMenuEdit;
