import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    NumberField,
} from 'react-admin';
import {useParams} from "react-router-dom";

import {RestaurantProductFilter} from "./RestaurantProductFilter";
import RestaurantProductListActions from "./RestaurantProductListActions";


export const RestaurantProductList = props => {
    const {restaurantId} = useParams();
    return (
        <List
            {...props}
            basePath={`/`}
            resource={'restaurant-products'}
            actions={<RestaurantProductListActions/>}
            filter={Object.assign(props.filter || {}, {restaurant: restaurantId})}
            filters={<RestaurantProductFilter/>}
        >
            <Datagrid rowClick={(id) => `/restaurants/${restaurantId}/restaurant-products/${id}`}>
                <TextField source="id"/>
                <TextField source="name"/>
                <NumberField source="price"/>
                <NumberField source="tax"/>
            </Datagrid>
        </List>
    )
};

export default RestaurantProductList;
