import * as React from "react";
import {Create} from 'react-admin';

import {useParams} from "react-router-dom";
import TakeAwayRequestForm from "./TakeAwayRequestForm";

const TakeAwayRequestCreate = props => {
    
    const { id} = useParams();

    return (

        <Create basePath={`/`}  resource={`take-away-requests`}  id={id}>
            <TakeAwayRequestForm {...props}/>
        </Create>
    );
};

export default TakeAwayRequestCreate;
