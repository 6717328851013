import * as React from 'react';
import {Fragment, useCallback, useState} from 'react';
import {
    Button,
    Confirm,
    useRefresh,
    useNotify,
    useUnselectAll, useDataProvider,useTranslate
} from 'react-admin';
import IconLinkOff from "@material-ui/icons/LinkOff";

const UnlinkRecipesButton = ({ restaurantId, selectedIds, ...props }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const dataProvider = useDataProvider();
    const translate = useTranslate();

    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState();

    const handleSave = useCallback(() => {
        setSaving(true);
        dataProvider.unlinkRecipes(restaurantId, { recipeIds: selectedIds })
            .then(({data}) => {
                notify(translate('resources.recipes.link_recipe.recipes_unlinked'), 'info', {
                    _: translate('resources.recipes.link_recipe.recipes_unlinked'),
                });
                unselectAll('recipes')
            }).catch(() => {
                notify(
                    translate('resources.recipes.link_recipe.error_message_unlink'),
                    'warning',
                    {
                        _: translate('resources.recipes.link_recipe.error_message_unlink')
                    }
                );
            })
            .finally(() => {
                setSaving(false);
                refresh();
            });
    }, [selectedIds, restaurantId, dataProvider, notify, refresh, unselectAll, translate]);

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        handleSave();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button
                label={translate('resources.recipes.link_recipe.title_unlinked')}
                onClick={handleClick}
            >
                <IconLinkOff/>
            </Button>
            <Confirm
                isOpen={open}
                loading={saving}
                title={translate('resources.recipes.link_recipe.title_unlinked')}
                content={translate('resources.recipes.link_recipe.subtitle_unlinked')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}

export default UnlinkRecipesButton;

