import * as React from "react";
import {Create} from 'react-admin';

import RecipeForm from "./RecipeForm";

const RecipeCreate = ({staticContext, basePath, ...props}) => {

    return (
        <Create {...props} basePath={`/`} resource={`recipes`}>
            <RecipeForm {...props} />
        </Create>
    );
};

export default RecipeCreate;
