import * as React from 'react';
import { useTranslate } from 'react-admin';

import PeopleIcon from '@material-ui/icons/People';

import CardWithIcon from './CardWithIcon';

const UnconfirmedBookings = ({ restaurantId, value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to={`/restaurants/${restaurantId}/reservations`}
            icon={PeopleIcon}
            title={translate('pos.restaurantDashboard.unconfirmedBooking')}
            subtitle={value}
        />
    );
};

export default UnconfirmedBookings;
