import * as React from "react";
import {cloneElement} from 'react';
import {
    TopToolbar,
    CreateButton,
    ExportButton,
} from 'react-admin';
import {useHistory} from 'react-router-dom';

const RestaurantProductListActions = (props) => {
    const history = useHistory();
    const {location} = history;
    return (
        <TopToolbar>
            {cloneElement(props.filters, {context: 'button'})}
            <CreateButton onClick={(e) => {
                e.preventDefault();
                history.push(`${location.pathname}/create`)
            }}/>
            <ExportButton resource={'restaurant-products'}/>
        </TopToolbar>
    )
};

export default RestaurantProductListActions;
