import {useCallback, useMemo, useState} from 'react';
import {
    SaveContextProvider,
    AutocompleteArrayInput,
    ChipField,
    SimpleForm,
    useNotify,
    useDataProvider,
    useGetList, 
    useRefresh,
    useTranslate
} from 'react-admin';

import {makeStyles} from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.default,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const RecipeLinkModal = props => {
    const {restaurantId, open, onClose} = props;

    const translate = useTranslate();

    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const classes = useStyles();
    const notify = useNotify();

    const [saving, setSaving] = useState();

    const handleSave = useCallback((values) => {
        setSaving(true);
        dataProvider.linkRecipes(restaurantId, values)
            .then(({data}) => {
                notify(translate('resources.recipes.link_recipe.recipes_linked'), 'info', {
                    _: translate('resources.recipes.link_recipe.recipes_linked')
                });
            }).catch(() => {
                notify(
                    translate('resources.recipes.link_recipe.error_message'),
                    'warning',
                    {
                        _: translate('resources.recipes.link_recipe.error_message')
                    }
                );
            })
            .finally(() => {
                setSaving(false);
                onClose();
                refresh();
            });
    }, [dataProvider, notify, restaurantId, refresh, onClose, translate]);

    const saveContext = useMemo(() => ({
        save: handleSave,
        saving
    }), [saving, handleSave]);

    const { data, loading, error } = useGetList('recipes');
    const notLinkedData = Object.keys(data)
        .map(id => data[id])
        .filter(recipe => !recipe.restaurants.map(restaurant => +restaurant.id).includes(+restaurantId));
    if (error) {
        notify(`Loading recipes error: ${error.message}`, 'warning');
    }

    return !loading && (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500}} 
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <h2 id="transition-modal-title">{translate('resources.recipes.link_recipe.title')}</h2>
                    <p id="transition-modal-description">{translate('resources.recipes.link_recipe.subtitle')}</p>

                    <SaveContextProvider value={saveContext}>
                        <SimpleForm save={handleSave}>
                            <AutocompleteArrayInput  label={translate('resources.recipes.link_recipe.recipe')}  source="recipeIds" choices={notLinkedData}>
                                <ChipField source="name"/>
                            </AutocompleteArrayInput>
                        </SimpleForm>
                    </SaveContextProvider>
                </div>
            </Fade>
        </Modal>
    );
};

export default RecipeLinkModal;
