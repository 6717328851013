import * as React from "react";
import {NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin';
import {useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    hidden: {
        display: "none"
    },
});


const RestaurantProductForm = props => {
    const { restaurantId } = useParams();
    const classes = useStyles();

    return (
        <SimpleForm {...props}>
            <TextInput fullWidth source="id" className={classes.hidden} disabled/>
            <ReferenceInput reference="restaurants" source="restaurant" defaultValue={restaurantId} className={classes.hidden} disabled>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput  fullWidth source="name"/>
            <TextInput  fullWidth source="description"
                       multiline/>
            <NumberInput  fullWidth source="price"/>
            <NumberInput  fullWidth source="tax"/>
        </SimpleForm>
    );
};

export default RestaurantProductForm;
