import * as React from 'react';
import {
    TextInput,
    NumberInput,
    TabbedForm,
    FormTab,
    SimpleFormIterator,
    ArrayInput,
    ImageInput,
    FileInput,
    ImageField,
    FileField,
    ReferenceArrayInput,
    ReferenceInput,
    AutocompleteInput,
    SelectArrayInput,
    ChipField,
} from 'react-admin';

import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const RecipeForm = props => {
    // const {restaurantId, id} = useParams();
    // console.log('restaurantId', restaurantId)
    // console.log('id', id)
    const classes = useStyles();

    return (
        <TabbedForm {...props}>
            <FormTab
                label="resources.recipes.tabs.details"
                // contentClassName={classes.tab}
            >
                <div className={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextInput fullWidth source="id" disabled/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput fullWidth source="name" label="resources.recipes.fields.name"/>
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput fullWidth source="prepTime" label="resources.recipes.fields.prepTime"/>
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput fullWidth source="cookTime" label="resources.recipes.fields.cookTime"/>
                        </Grid>

                        <Grid item xs={6}>
                            <NumberInput fullWidth source="cost" label="resources.recipes.fields.cost"/>
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput fullWidth source="difficulty" label="resources.recipes.fields.difficulty"/>
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput fullWidth source="servings" label="resources.recipes.fields.servings"/>
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput fullWidth source="source" label="resources.recipes.fields.servings"/>
                        </Grid>
                        <Grid item xs={6}>
                            <ReferenceArrayInput
                                source="categories" reference="categories" fullWidth
                                label="resources.recipes.fields.categories"
                                sort={{field: 'name', order: 'ASC'}}>
                                {/* <AutocompleteInput optionText="name" multiline /> */}
                                {/* <SelectInput optionText="name" multiple /> */}
                                <SelectArrayInput>
                                    <ChipField source="name"/>
                                </SelectArrayInput>

                            </ReferenceArrayInput>
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput multiline fullWidth source="keywords" label="resources.recipes.fields.keywords"
                                       rows={2}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput multiline fullWidth source="description"
                                       label="resources.recipes.fields.description" rows={2}/>
                        </Grid>
                    </Grid>
                </div>
            </FormTab>

            <FormTab
                label="resources.recipes.tabs.ingredients"
            >
                <ArrayInput source="ingredients">
                    <SimpleFormIterator>
                        <ReferenceInput label="resources.recipes.fields.product.name" source="product.id"
                                        reference="products" fullWidth>
                            <AutocompleteInput optionText="name"/>
                        </ReferenceInput>
                        <NumberInput label="resources.recipes.fields.quantity" source="quantity" fullWidth/>
                        <ReferenceInput label="resources.recipes.fields.unit.name" source="unit.id" reference="units"
                                        fullWidth>
                            <AutocompleteInput optionText="name"/>
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>

            <FormTab
                label="resources.recipes.tabs.instructions"
            >
                <ArrayInput source="instructions">
                    <SimpleFormIterator>
                        {/* <TextInput source="id" fullWidth/> */}
                        <TextInput source="name" fullWidth/>
                        <TextInput multiline source="description" fullWidth/>
                        <ImageInput multiple source="image" accept="image/*" fullWidth>
                            <ImageField source="formats.small.url" title="title"/>
                        </ImageInput>
                        <FileInput multiple source="video" fullWidth>
                            <FileField source="formats.small.url" title="title"/>
                        </FileInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>

            <FormTab
                label="resources.recipes.tabs.assets"
            >
                <ImageInput multiple source="assets" accept="image/*" fullWidth>
                    <ImageField source="url" title="title"/>
                </ImageInput>
            </FormTab>

        </TabbedForm>
    );
};

export default RecipeForm;
