import * as React from 'react';
import { Route } from "react-router-dom";

import Dashboard from "./components/dashboard/Dashboard";
import RestaurantGridList from "./components/restaurants/RestaurantGridList";
import RestaurantDashboard from './components/restaurants/RestaurantDashboard';
import RecipeList from './components/recipe/RecipeList';
import RecipesEdit from './components/recipe/RecipeEdit';
import RecipeCreate from './components/recipe/RecipeCreate';
import RestaurantProductList from './components/restaurantProducts/RestaurantProductList';
import RestaurantProductEdit from './components/restaurantProducts/RestaurantProductEdit';
import RestaurantProductCreate from './components/restaurantProducts/RestaurantProductCreate';
import RestaurantMenuList from './components/restaurantMenus/RestaurantMenuList';
import RestaurantMenuEdit from './components/restaurantMenus/RestaurantMenuEdit';
import RestaurantMenuCreate from './components/restaurantMenus/RestaurantMenuCreate';
import ReservationList from './components/reservations/ReservationList';
import ReservationCreate from './components/reservations/ReservationCreate';
import ReservationEdit from './components/reservations/ReservationEdit';
import TakeAwayRequestList from './components/takeAwayRequests/TakeAwayRequestList';
import TakeAwayRequestEdit from './components/takeAwayRequests/TakeAwayRequestEdit';
import TakeAwayRequestCreate from './components/takeAwayRequests/TakeAwayRequestCreate';
import RestaurantProfileEdit from './components/restaurants/RestaurantProfileEdit';
// import Support from './components/support/support';
import ContactCreate from './components/support/ContactCreate';
import Faq from './components/faq/Faq';
import Settings from "./components/settings/Settings";
import UserProfileEdit from './components/user/UserProfileEdit';
import ForgotPasswordPage from './layout/ForgotPassword';
import ResetPasswordPage from './layout/ResetPassword';

const routes = [
    <Route path="/dashboard" component={Dashboard} />,
    // <Route path="/restaurants/:restaurantId/categories/create" component={CategoryCreate} />,
    // <Route path="/restaurants/:restaurantId/categories/:id" component={CategoryEdit} />,
    // <Route path="/restaurants/:restaurantId/categories/:id/show" component={CategoryShow} />,

    <Route path="/restaurants/:restaurantId/recipes/create" component={RecipeCreate} />,
    <Route path="/restaurants/:restaurantId/recipes/:id" component={RecipesEdit} />,
    <Route path="/restaurants/:restaurantId/recipes" component={RecipeList} />,

    <Route path="/restaurants/:restaurantId/restaurant-products/create" component={RestaurantProductCreate} />,
    <Route path="/restaurants/:restaurantId/restaurant-products/:id" component={RestaurantProductEdit} />,
    <Route path="/restaurants/:restaurantId/restaurant-products" component={RestaurantProductList} />,

    <Route path="/restaurants/:restaurantId/restaurant-menus/create" component={RestaurantMenuCreate} />,
    <Route path="/restaurants/:restaurantId/restaurant-menus/:id" component={RestaurantMenuEdit} />,
    <Route path="/restaurants/:restaurantId/restaurant-menus" component={RestaurantMenuList} />,

    <Route path="/restaurants/:restaurantId/reservations/create" component={ReservationCreate} />,
    <Route path="/restaurants/:restaurantId/reservations/:id" component={ReservationEdit} />,
    <Route path="/restaurants/:restaurantId/reservations" component={ReservationList} />,

    <Route path="/restaurants/:restaurantId/take-away-requests/create" component={TakeAwayRequestCreate} />,
    <Route path="/restaurants/:restaurantId/take-away-requests/:id" component={TakeAwayRequestEdit} />,
    <Route path="/restaurants/:restaurantId/take-away-requests" component={TakeAwayRequestList} />,

    <Route path="/restaurants/:restaurantId/profile" component={RestaurantProfileEdit} />,

    <Route path="/restaurants/:restaurantId" component={RestaurantDashboard} />,
    <Route path="/restaurants" component={RestaurantGridList} />,
    <Route path="/support" component={ContactCreate} />,
    <Route path="/faq" component={Faq} />,
    <Route path="/user-profile" component={UserProfileEdit} />,
    <Route path="/forgot-password" component={ForgotPasswordPage}  noLayout/>,
    <Route path="/reset-password" component={ResetPasswordPage}  noLayout/>,
    // <Route path="/support" component={Support} />,
    <Route path="/settings" component={Settings} />,
];
export default routes;


