import {Filter, TextInput, useLocale, useTranslate} from "react-admin"
import * as React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {enUS, pt} from "date-fns/locale";

import {DateTimeInput} from "../datetime";

export const RestaurantMenuFilter = (props) => {
    const locale = useLocale();
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput label="pos.search" source="q" alwaysOn/>
            <DateTimeInput
                label={translate('resources.restaurant-menus.fields.startDate_gte')}
                source="startDate_gte"
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel')
                }}
                providerOptions={{utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS}}
            />
            <DateTimeInput
                label={translate('resources.restaurant-menus.fields.startDate_lte')}
                source="startDate_lte"
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel')
                }}
                providerOptions={{utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS}}
            />
            <DateTimeInput
                label={translate('resources.restaurant-menus.fields.endDate_gte')}
                source="endDate_gte"
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel')
                }}
                providerOptions={{utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS}}
            />
            <DateTimeInput
                label={translate('resources.restaurant-menus.fields.endDate_lte')}
                source="endDate_lte"
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel')
                }}
                providerOptions={{utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS}}
            />
            <DateTimeInput
                label={translate('resources.restaurant-menus.fields.created_at_gte')}
                source="created_at_gte"
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel')
                }}
                providerOptions={{utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS}}
            />
            <DateTimeInput
                label={translate('resources.restaurant-menus.fields.created_at_lte')}
                source="created_at_lte"
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel')
                }}
                providerOptions={{utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS}}
            />
        </Filter>
    )
};
