import * as React from "react";
import {Create} from 'react-admin';

import {useParams} from "react-router-dom";
import ReservationForm from "./ReservationForm";

const ReservationCreate = props => {
    
    const { id} = useParams();

    return (

        <Create basePath={`/`}  resource={`reservations`}  id={id}>
            <ReservationForm {...props}/>
        </Create>
    );
};

export default ReservationCreate;
