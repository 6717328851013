import * as React from "react";
import {
    NumberInput, ReferenceInput, SelectInput, ReferenceArrayInput, SelectArrayInput, ChipField,
    SimpleForm, TextInput, AutocompleteInput, useTranslate, useLocale
} from 'react-admin';
import {useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {enUS, pt} from "date-fns/locale";

import {DateTimeInput} from "../datetime";


const useStyles = makeStyles({
    hidden: {
        display: "none"
    },
});


const ReservationForm = props => {
    const { restaurantId } = useParams();
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();

    return (
        <SimpleForm {...props}>
            <TextInput fullWidth source="id" className={classes.hidden} disabled/>
            <ReferenceInput reference="restaurants" source="restaurant" defaultValue={restaurantId} className={classes.hidden} disabled>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput fullWidth source="id" className={classes.hidden} disabled/>


            <DateTimeInput
                source="date" fullWidth
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel'),
                    inputVariant: 'filled'
                }}
                providerOptions={{ utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS }}
            />
           <NumberInput source="seats" fullWidth />

           <ReferenceArrayInput  label="Menus" source="menus" reference="restaurant-menus" fullWidth>
                <SelectArrayInput>
                    <ChipField source="name" />
                </SelectArrayInput>
            </ReferenceArrayInput>

            <ReferenceArrayInput  label="resources.reservations.fields.extraProducts"  source="extraProducts" reference="restaurant-products" fullWidth>
                        <SelectArrayInput>
                                    <ChipField source="name" />
                                </SelectArrayInput>
            </ReferenceArrayInput>


            <ReferenceInput  label="resources.reservations.fields.author" source="author" reference="users" fullWidth>
                    <AutocompleteInput optionText="username"  />
            </ReferenceInput>


        </SimpleForm>
    );
};

export default ReservationForm;
