import * as React from 'react';
import { useTranslate } from 'react-admin';

import RateReviewIcon from '@material-ui/icons/RateReview';

import CardWithIcon from './CardWithIcon';

const ReviewsLast7Days = ({ restaurantId, value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to={`/restaurants/${restaurantId}/reviews`}
            icon={RateReviewIcon}
            title={translate('pos.restaurantDashboard.reviewsLast7Days')}
            subtitle={value}
        />
    );
};

export default ReviewsLast7Days;
