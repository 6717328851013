import React from 'react';
import {MenuItemLink, Responsive, useTranslate, Button} from 'react-admin';
import {useLocation} from "react-router-dom";
import {useHistory} from 'react-router-dom';

import {Divider, Paper} from "@material-ui/core";
import DashboardIcon from '@material-ui/icons/Dashboard';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import ContactIcon from '@material-ui/icons/ContactSupport';
import ContactPage from '@material-ui/icons/Contacts';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import BookIcon from '@material-ui/icons/EventAvailable';
import TakeAwayIcon from '@material-ui/icons/LocalDining';
import SettingsIcon from '@material-ui/icons/Settings';
import {makeStyles} from "@material-ui/core/styles";

const restaurantMenuItems = [
    {name: 'dashboard', isSubMenu: true, i18n: 'menu.dashboard', icon: <DashboardIcon/>},
    {name: 'recipes', isSubMenu: true, i18n: 'menu.recipes', icon: <MenuBookIcon/>},
    {name: 'restaurant-products', isSubMenu: true, i18n: 'menu.restaurantProducts', icon: <ShoppingBasketIcon/>},
    {name: 'restaurant-menus', isSubMenu: true, i18n: 'menu.menu', icon: <RestaurantIcon/>},
    {name: 'reservations', isSubMenu: true, i18n: 'menu.reservations', icon: <BookIcon/>},
    {name: 'take-away-requests', isSubMenu: true, i18n: 'menu.take-away-request', icon: <TakeAwayIcon/>},
    <Divider/>,
    {name: 'profile', isSubMenu: true, i18n: 'menu.settings', icon: <SettingsIcon/>},
];

const mainMenuItems = [
    {name: 'dashboard', i18n: 'menu.dashboard', icon: <DashboardIcon/>},
    {name: 'faq', i18n: 'menu.faq', icon: <ContactIcon/>},
    {name: 'support', i18n: 'menu.support', icon: <ContactPage/>},
];

function getRestaurantId(pathname) {
    const listPathname = pathname.split('/');
    if (listPathname.length < 3)
        return -1;

    if (listPathname[1] !== 'restaurants')
        return -1;

    return listPathname[2]
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingTop: '8px',
        paddingBottom: '8px'
    },
    button: {
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main}1e`,
            color: theme.palette.text.primary
        },
    buttonBack: {
        // height: '50px',
        }
    }
}));

const Menu = ({onMenuClick, logout}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const location = useLocation();
    const restaurantID = getRestaurantId(location.pathname)
    const history = useHistory();

    return (
        <Paper elevation={0} className={classes.root}>
           
            {/* {restaurantID !== -1 && <MenuItemLink
                key="dashboard"
                to="/dashboard"
                primaryText={translate("menu.exitRestaurant")}
                leftIcon={<ArrowBackIcon/>}
                // onClick={onMenuClick}
                onClick={()=>alert(34)}
                className={classes.button}
            />} */}

            {restaurantID !== -1 && <Button
                className={classes.buttonBack}
                onClick={()=>{
                    const {goBack} = history;
                    goBack();
                }}
                // className={classes.button}
            ><ArrowBackIcon/></Button>}

            {restaurantID !== -1 && <Divider/>}

            {restaurantID !== -1 && restaurantMenuItems.map(item => {
                if (React.isValidElement(item)) return item;
                return (
                    <MenuItemLink
                        key={item.name}
                        to={`/restaurants/${restaurantID}/${item.name}`}
                        primaryText={translate(item.i18n)}
                        leftIcon={item.icon}
                        onClick={onMenuClick}
                        className={classes.button}
                    />
                );
            })}

            {restaurantID === -1 && mainMenuItems.map(item => (
                <MenuItemLink
                    key={item.name}
                    to={`/${item.name}`}
                    primaryText={translate(item.i18n)}
                    leftIcon={item.icon}
                    onClick={onMenuClick}
                    className={classes.button}
                />
            ))}

            <Responsive
                small={logout}
                medium={null}
            />
        </Paper>
    )
};

export default Menu;

