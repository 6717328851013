import {Filter, TextInput, useTranslate} from "react-admin"
import * as React from "react";

import {DateTimeInput} from "../datetime";

export const RecipeFilter = (props) => {

    const translate = useTranslate();

    return (
    <Filter {...props}>
        <TextInput label="pos.search" source="q" alwaysOn />
        <TextInput source="difficulty_gte" />
        <TextInput source="difficulty_lte" />
        <TextInput source="servings_gte" />
        <TextInput source="servings_lte" />
        <TextInput source="cost_gte" />
        <TextInput source="cost_lte" />
        <TextInput source="prepTime_gte" />
        <TextInput source="prepTime_lte" />
        <TextInput source="cookTime_gte" />
        <TextInput source="cookTime_lte" />
        <DateTimeInput label={translate('resources.recipes.fields.created_at_gte')} source="created_at_gte" options={{ format: 'dd/MM/yyyy, HH:mm:ss', ampm: false, clearable: true }}/>
        <DateTimeInput label={translate('resources.recipes.fields.created_at_lte')} source="created_at_lte" options={{ format: 'dd/MM/yyyy, HH:mm:ss', ampm: false, clearable: true }}/>
    </Filter>
);}
