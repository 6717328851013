import * as React from "react";
import {Edit} from 'react-admin';
import {useParams} from "react-router-dom";
import RestaurantProductForm from "./RestaurantProductForm";


const RestaurantProductEdit = props => {
    const {id} = useParams();
    
    return (
        <Edit basePath={`/`} resource={`restaurant-products`} id={id}>
           <RestaurantProductForm {...props}/>
        </Edit>
    );
};

export default RestaurantProductEdit;
