import * as React from "react";
import {useParams} from "react-router-dom";

import {Grid} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";


import RestaurantCharts from "./RestaurantCharts";
import IncomeLastMonth from "./IncomeLastMonth";
import UnconfirmedBookings from "./UnconfirmedBookings";
import ReviewsLast7Days from "./ReviewsLast7Days";
import SolidarityContributionLastMonth from "./SolidarityContributionLastMonth";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    button: {
        minHeight: '60px',
    }
}));

const RestaurantDashboard = () => {
    const classes = useStyles();
    const {restaurantId} = useParams();
  

    return (
        <div className={classes.root}>
            {/* <h3>Requested restaurant ID: {restaurantId}</h3> */}
            {/*{
                !loading &&
                <>
                <h1>{translate('pos.restaurantDashboard.restaurant') +': '+data.name}</h1>
                </>
            }*/}

            <Grid container spacing={2}  alignItems="stretch">

                <Grid item sm={6} md={3}>
                    <IncomeLastMonth restaurantId={restaurantId} value={3300}/>
                </Grid>

                <Grid item sm={6} md={3}>
                    <UnconfirmedBookings restaurantId={restaurantId} value={3}/>
                </Grid>

                <Grid item sm={6} md={3}>
                    <ReviewsLast7Days restaurantId={restaurantId} value={7}/>
                </Grid>

                <Grid item sm={6} md={3}>
                    <SolidarityContributionLastMonth restaurantId={restaurantId} value={333}/>
                </Grid>
            </Grid>

            <RestaurantCharts/>

        </div>
    );
}

export default RestaurantDashboard;
