import * as React from "react";
import {Create} from 'react-admin';

import {useParams} from "react-router-dom";
import RestaurantProductForm from "./RestaurantProductForm";

const RestaurantProductCreate = props => {
    
    const { id} = useParams();

    return (

        <Create basePath={`/`}  resource={`restaurant-products`}  id={id}>
            <RestaurantProductForm {...props}/>
        </Create>
    );
};

export default RestaurantProductCreate;
