import * as React from "react";
import {Edit} from 'react-admin';
import {useParams} from "react-router-dom";
import RestaurantProfileForm from "./RestaurantProfileForm";


const RestaurantProfileEdit = props => {
    const {restaurantId} = useParams();

    const transform = data => ({
        ...data,
        slots: data.slots.map(slot => ({
            ...slot,
            slots: slot.slots.map(timeSlot => ({
                ...timeSlot,
                open: new Date(timeSlot.open).toTimeString().slice(0, 8),
                close: new Date(timeSlot.close).toTimeString().slice(0, 8),
            }))
        }))
    });

    return (
        <Edit basePath={`/`} resource={`restaurants`} id={restaurantId} transform={transform}>
           <RestaurantProfileForm {...props}/>
        </Edit>
    );
};

export default RestaurantProfileEdit;
