import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
} from 'react-admin';
import {useParams} from "react-router-dom";

import {ReservationFilter} from "./ReservationFilter";
import ReservationListActions from "./ReservationListActions";

export const ReservationList = props => {
    const {restaurantId} = useParams();
    return (
        <List
            {...props}
            basePath={`/`}
            resource={'reservations'}
            actions={<ReservationListActions/>}
            filter={Object.assign(props.filter || {}, {restaurant: restaurantId})}
            filters={<ReservationFilter/>}
        >
            <Datagrid rowClick={(id) => `/restaurants/${restaurantId}/reservations/${id}`}>
                <TextField source="id"/>
                <DateField source="date"/>
                <NumberField source="seats"/>
                <ReferenceField label="resources.reservations.fields.author" source="author.id" reference="users">
                    <TextField source="username"/>
                </ReferenceField>
            </Datagrid>
        </List>
    )
};

export default ReservationList;
