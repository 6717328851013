import * as React from "react";
import {
    NumberInput, ReferenceInput, SelectInput, ReferenceArrayInput, SelectArrayInput, ChipField,
    SimpleForm, TextInput, ArrayInput, SimpleFormIterator, AutocompleteInput, useTranslate, useLocale
} from 'react-admin';
import {useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {enUS, pt} from "date-fns/locale";

import {DateTimeInput} from "../datetime";

const useStyles = makeStyles({
    hidden: {
        display: "none"
    },
});


const TakeAwayRequestForm = props => {
    const { restaurantId } = useParams();

    const translate = useTranslate();
    const locale = useLocale();

    const classes = useStyles();

    return (
        <SimpleForm {...props}>
            <TextInput fullWidth source="id" className={classes.hidden} disabled/>
            <ReferenceInput reference="restaurants" source="restaurant" defaultValue={restaurantId} className={classes.hidden} disabled>
                <SelectInput optionText="name" />
            </ReferenceInput>

            <TextInput  fullWidth source="description"
                       multiline/>
            <DateTimeInput
                fullWidth source="date"
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel'),
                    inputVariant: 'filled'
                }}
                providerOptions={{ utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS }}
            />


            <ReferenceArrayInput   source="other_products" reference="restaurant-products" fullWidth>
                        <SelectArrayInput>
                                    <ChipField source="name" />
                                </SelectArrayInput>
            </ReferenceArrayInput>

            <ReferenceInput  label="resources.take-away-requests.fields.charity" source="charity" reference="charities" fullWidth>
                            <AutocompleteInput optionText="name"  />
                        </ReferenceInput>

            <ArrayInput source="entries">
                    <SimpleFormIterator>

                        <ReferenceInput  label="resources.take-away-requests.fields.name" source="recipe.id" reference="recipes" fullWidth>
                            <AutocompleteInput optionText="name"  />
                        </ReferenceInput>
                        <TextInput multiline   source="description" fullWidth/>

                        <NumberInput   source="servings" fullWidth/>
                        <NumberInput   source="price" fullWidth/>
                        <NumberInput   source="tax" fullWidth/>

                    </SimpleFormIterator>
                </ArrayInput>


        </SimpleForm>
    );
};

export default TakeAwayRequestForm;
