import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
} from 'react-admin';
import {useParams} from 'react-router-dom';

import {RecipeFilter} from './RecipeFilter';
import RecipeListActions from './RecipeListActions';
import RecipeBulkActionButtons from './RecipeBulkActionButtons';


const RecipeList = props => {
    const {staticContext, ...restProps} = props;
    const {restaurantId} = useParams();

    return (
        <List
            {...restProps}
            basePath='/'
            resource='recipes'
            filters={<RecipeFilter/>}
            filter={Object.assign(restProps.filter || {}, {restaurants: restaurantId})}
            actions={<RecipeListActions/>}
            bulkActionButtons={<RecipeBulkActionButtons restaurantId={restaurantId}/>}
        >
            <Datagrid rowClick={(id) => `/restaurants/${restaurantId}/recipes/${id}`}>
                <NumberField source="id"/>
                <TextField source="name"/>
                {/* <TextField source="cost" /> */}
                {/* <ArrayField source="assets"><SingleFieldList><ChipField source="_id" /></SingleFieldList></ArrayField> */}
                {/* <ArrayField source="categories"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField> */}
                {/* <TextField source="viewers" /> */}
                {/* <ReferenceField source="_id" reference="s"><TextField source="id" /></ReferenceField> */}
                {/* <NumberField source="preparationTime" /> */}
                {/* <NumberField source="servings" /> */}
                {/* <NumberField source="cookTime" /> */}
                <TextField source="difficulty"/>
                <TextField source="status"/>
                {/* <ArrayField source="step"><SingleFieldList><ChipField source="hint" /></SingleFieldList></ArrayField> */}
                {/* <ArrayField source="tag"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>  */}
                {/* <DateField source="createdAt" />
                {/* <DateField source="updatedAt" /> */}
                {/* <NumberField source="__v" /> */}
                {/* <BooleanField source="author.confirmed" /> */}
                {/* <NumberField source="views" /> */}
                {/* <ArrayField source="reviews"><SingleFieldList><ChipField source="assets" /></SingleFieldList></ArrayField> */}
                {/* <TextField source="id" /> */}
                <NumberField source="rating"/>
                {/* <EditButton /> */}
                {/* <NumberField source="reviewsCount" /> */}
            </Datagrid>
        </List>
    )
};

export default RecipeList;
