import * as React from "react";
import {Create} from 'react-admin';

import {useParams} from "react-router-dom";
import RestaurantMenuForm from "./RestaurantMenuForm";

const RestaurantMenuCreate = props => {
    
    const { id} = useParams();

    return (

        <Create basePath={`/`}  resource={`restaurant-menus`}  id={id}>
            <RestaurantMenuForm {...props}/>
        </Create>
    );
};

export default RestaurantMenuCreate;
