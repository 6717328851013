import {Link} from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import IconLocation from '@material-ui/icons/LocationOn';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        textDecoration: "none",
    },
    media: {
        height: 0,
        paddingTop: "56.25%", // 16:9
    },
    title: {
        fontSize: '1.5rem',
        marginBottom: '0.5rem'
    },
    subtitle: {
        fontSize: '1.25rem',
        display: 'flex',
        alignItems: 'center'
    },
    content: {
        '&:last-child': {
            padding: theme.spacing(2),
        }
    },
    addressWrapper: {
        display: 'flex',
        justifyContent: 'row',
    }

}));

const RestaurantCard = ({value}) => {
    const classes = useStyles();

    return (
        <Link to={`/restaurants/${value.id}`} state={{value}} className={classes.root}>
            <Card elevation={2}>
                <CardMedia
                    className={classes.media}
                    image={value.logo.url}
                />

                <CardContent className={classes.content}>
                    <div className={classes.title}>{value.name}</div>
                    <div className={classes.addressWrapper}>
                        <div className={classes.subtitle}>
                            <IconLocation color="primary"/>
                            {value.address.city + ', ' + value.address.country}
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Link>
    );
}

export default RestaurantCard;
