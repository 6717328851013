import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
} from 'react-admin';
import {useParams} from "react-router-dom";

import {RestaurantMenuFilter} from "./RestaurantMenuFilter";
import RestaurantMenuListActions from "./RestaurantMenuListActions";

export const RestaurantMenuList = props => {
    const {restaurantId} = useParams();
    return (
        <List
            {...props}
            basePath={`/`}
            resource={'restaurant-menus'}
            actions={<RestaurantMenuListActions/>}
            filter={Object.assign(props.filter || {}, {restaurant: restaurantId})}
            filters={<RestaurantMenuFilter/>}
        >
            <Datagrid rowClick={(id) => `/restaurants/${restaurantId}/restaurant-menus/${id}`}>
                <TextField source="id"/>
                <TextField source="name"/>
                <DateField source="startDate"/>
                <DateField source="endDate"/>
                <TextField source="totalPrice"/>
            </Datagrid>
        </List>
    )
};

export default RestaurantMenuList;
