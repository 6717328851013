import * as React from "react";
import {Edit} from 'react-admin';
import {useParams} from 'react-router-dom';

import RecipeForm from './RecipeForm';


const RecipeEdit = ({staticContext, basePath, ...props}) => {
    const {id} = useParams();

    return (
        <Edit {...props} basePath='/' resource='recipes' id={id}>
            <RecipeForm {...props} />
        </Edit>
    );
};

export default RecipeEdit;
