import * as React from "react";
import {
    NumberInput, ReferenceInput, SelectInput, ReferenceArrayInput,
    SelectArrayInput, ChipField, ImageInput, ImageField,
    SimpleForm, TextInput, ArrayInput, SimpleFormIterator, AutocompleteInput, useTranslate, useLocale
} from 'react-admin';
import {useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {enUS, pt} from "date-fns/locale";

import {DateTimeInput} from "../datetime";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles({
    hidden: {
        display: "none"
    },
});


const RestaurantProductForm = props => {
    const {restaurantId} = useParams();
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();

    return (
        <SimpleForm {...props}>
            <TextInput fullWidth source="id" className={classes.hidden} disabled/>
            <ReferenceInput reference="restaurants" source="restaurant" defaultValue={restaurantId}
                            className={classes.hidden} disabled>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput fullWidth source="name"/>
            <TextInput fullWidth source="description"
                       multiline/>

            <Grid container fullWidth>
                <Grid item xs={6} spacing={2}>
                    <DateTimeInput
                        fullWidth source="startDate"
                        options={{
                            format: 'dd/MM/yyyy, HH:mm:ss',
                            ampm: false,
                            clearable: true,
                            clearLabel: translate('dialog.actions.clearLabel'),
                            cancelLabel: translate('dialog.actions.cancelLabel'),
                            okLabel: translate('dialog.actions.okLabel'),
                            inputVariant: 'filled'
                        }}
                        providerOptions={{ utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS }}
                    />
                </Grid>
                <Grid item xs={6} spacing={2}>
                    <DateTimeInput
                        fullWidth source="endDate"
                        options={{
                            format: 'dd/MM/yyyy, HH:mm:ss',
                            ampm: false,
                            clearable: true,
                            clearLabel: translate('dialog.actions.clearLabel'),
                            cancelLabel: translate('dialog.actions.cancelLabel'),
                            okLabel: translate('dialog.actions.okLabel'),
                            inputVariant: 'filled'
                        }}
                        providerOptions={{ utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS }}
                    />
                </Grid>
            </Grid>

            <ReferenceArrayInput label="Product" source="otherProducts" reference="restaurant-products" fullWidth>
                {/* <AutocompleteInput multiple optionText="name"  /> */}
                <SelectArrayInput>
                    <ChipField source="name"/>
                </SelectArrayInput>
            </ReferenceArrayInput>

            <ArrayInput source="entries">
                <SimpleFormIterator>

                    <ReferenceInput label="resources.restaurant-menus.fields.name" source="recipe.id"
                                    reference="recipes" fullWidth>
                        <AutocompleteInput optionText="name"/>
                    </ReferenceInput>
                    <TextInput multiline source="description" fullWidth/>

                    <NumberInput source="servings" fullWidth/>
                    <NumberInput source="price" fullWidth/>
                    <NumberInput source="tax" fullWidth/>

                </SimpleFormIterator>
            </ArrayInput>

            <ImageInput multiple source="assets" accept="image/*" fullWidth>
                <ImageField source="url" title="title"/>
            </ImageInput>


        </SimpleForm>
    );
};

export default RestaurantProductForm;
