import * as React from "react";

import RestaurantGridList from '../restaurants/RestaurantGridList';

const Dashboard = () => {
    return (
        <div>
            <RestaurantGridList/>
        </div>
    );
};

export default Dashboard;
