import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    SingleFieldList,
    ArrayField,
    ChipField
} from 'react-admin';
import {useParams} from "react-router-dom";

import {TakeAwayRequestFilter} from "./TakeAwayRequestFilter";
import TakeAwayRequestListActions from "./TakeAwayRequestListActions";


export const TakeAwayRequestList = props => {
    const {restaurantId} = useParams();
    return (
        <List
            {...props}
            basePath={`/`}
            resource={'take-away-requests'}
            actions={<TakeAwayRequestListActions/>}
            filter={Object.assign(props.filter || {}, {restaurant: restaurantId})}
            filters={<TakeAwayRequestFilter/>}
        >
            <Datagrid rowClick={(id) => `/restaurants/${restaurantId}/take-away-requests/${id}`}>
                <TextField source="id"/>
                <DateField source="date"/>
                <ArrayField source="entries">
                    <SingleFieldList>
                        <ChipField source="recipe.name"/>
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="other_products"><SingleFieldList><ChipField
                    source="name"/></SingleFieldList></ArrayField>
                <TextField label="resources.take-away-requests.fields.charity" source="charity.name"/>
            </Datagrid>
        </List>
    )
};
export default TakeAwayRequestList;
