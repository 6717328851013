import * as React from "react";
import {cloneElement} from "react";
import {useHistory} from "react-router-dom";
import {CreateButton, ExportButton, TopToolbar} from "react-admin";

const ReservationListActions = (props) => {
    const history = useHistory();
    const {location} = history;
    return (
        <TopToolbar>
            {cloneElement(props.filters, {context: 'button'})}
            <CreateButton onClick={(e) => {
                e.preventDefault();
                history.push(`${location.pathname}/create`)
            }}/>
            <ExportButton resource={'reservations'} />
        </TopToolbar>
    )
};

export default ReservationListActions;
