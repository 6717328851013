import * as React from 'react';
import {Filter, TextInput, ReferenceInput, AutocompleteInput, useLocale, useTranslate} from 'react-admin'
import DateFnsUtils from '@date-io/date-fns';
import { enUS, pt } from 'date-fns/locale'

import {DateTimeInput} from "../datetime";

export const ReservationFilter = (props) => {
    const locale = useLocale();
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn/>
            <ReferenceInput source="author" reference="users">
                <AutocompleteInput
                    // optionText={(choice) =>
                    //     choice.id // the empty choice is { id: '' }
                    //         ? `${choice.first_name} ${choice.last_name}`
                    //         : ''
                    // }
                    optionText="username"
                />
            </ReferenceInput>
            <TextInput source="seats_gte"/>
            <TextInput source="seats_lte"/>
            <DateTimeInput
                source="date_gte"
                label={translate('resources.reservations.fields.date_gte')}
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel')
                }}
                providerOptions={{ utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS }}
            />
            <DateTimeInput
                source="date_lte"
                label={translate('resources.reservations.fields.date_lte')}
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel')
                }}
                providerOptions={{ utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS }}
            />
            <DateTimeInput
                source="created_at_gte"
                label={translate('resources.reservations.fields.created_at_gte')}
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel')
                }}
                providerOptions={{ utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS }}
            />
            <DateTimeInput
                source="created_at_lte"
                label={translate('resources.reservations.fields.created_at_lte')}
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel')
                }}
                providerOptions={{ utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS }}
            />
        </Filter>
    );
};
