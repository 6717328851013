import * as React from 'react';
import { useTranslate } from 'react-admin';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import CardWithIcon from './CardWithIcon';

const IncomeLastMonth = ({ restaurantId, value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to={`/restaurants/${restaurantId}/reservations`}
            icon={AttachMoneyIcon}
            title={translate('pos.restaurantDashboard.incomeInTheLastMonth')}
            subtitle={value}
        />
    );
};

export default IncomeLastMonth;
