import * as React from "react";
import {useHistory} from 'react-router-dom';
import {Loading, useDataProvider, useRefresh} from "react-admin";

import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import RestaurantCard from "./RestaurantCard";
import {useEffect, useState} from "react";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    }
}));

const RestaurantGridList = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        dataProvider.myRestaurants('restaurants')
            .then((response) => {
                if (response && Array.isArray(response.data)) {
                    setData(response.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false);
                refresh();
            })
    }, [dataProvider, setLoading, refresh])

    // let { topicId } = useParams();
    // console.log("topicId")
    // console.log(topicId)

    /*const { data, loading, error } = useQuery({
        type: 'myRestaurants',
        resource: 'restaurant',
        payload: {}
    });*/

    if (loading) return <Loading/>
    /*if (error) {
        return <p>{JSON.stringify(error)}</p>
    }*/

    if (data.length === 1) {
        history.push(`/restaurants/${data[0].id}`);
    }

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={2}
                direction="row"
            >
                {data.map((restaurant) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={"restaurant-"+restaurant.id}>
                        <RestaurantCard
                            key={restaurant.id}
                            value={restaurant}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}


export default RestaurantGridList;
