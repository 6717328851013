import * as React from 'react';
import { Fragment } from 'react';
import { BulkDeleteButton } from 'react-admin';

import UnlinkRecipesButton from './UnlinkRecipesButton';

const RecipeBulkActionButtons = props => (
    <Fragment>
        <UnlinkRecipesButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
);

export default RecipeBulkActionButtons;
