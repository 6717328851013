import * as React from "react";
import {
    ReferenceArrayInput, ArrayInput, SimpleFormIterator, BooleanInput, useTranslate,
    SimpleForm, SelectInput, TextInput, AutocompleteArrayInput, ImageField, ImageInput,
    ChipField, useLocale
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import RichTextInput from 'ra-input-rich-text';
import {Grid} from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import {enUS, pt} from "date-fns/locale";

import {TimeInput} from "../datetime";


const useStyles = makeStyles({
    hidden: {
        display: "none"
    },
    amenities: {
        marginTop: '16px',
        marginBottom: '8px',
        width: '100%',
        '& > label': {
            display: 'block',
            fontSize: '16px',
            transform: 'scale(0.75)',
            transformOrigin: 'top left'
        }
    },
    flexRow: {
        '& > section': {
            display: 'flex',
            flexDirection: 'column'
        }

    }
});


const RestaurantProfileForm = props => {
    const classes = useStyles();
    const locale = useLocale();
    const translate = useTranslate();

    return (
        <SimpleForm {...props}>
            <TextInput fullWidth source="id" className={classes.hidden} disabled/>

            <TextInput label="resources.restaurants.fields.name" fullWidth source="name"/>

            <RichTextInput label="resources.restaurants.fields.description" fullWidth source="description" multiline/>

            <Grid container spacing={2} fullWidth>

                <Grid item xs={6}><TextInput label="resources.restaurants.fields.email" type="email" fullWidth
                                             source="email"/> </Grid>
                <Grid item xs={6}><TextInput label="resources.restaurants.fields.phone" type="phone" fullWidth
                                             source="phone"/></Grid>
                <Grid item xs={6}><TextInput label="resources.restaurants.fields.mobile" type="mobile" fullWidth
                                             source="mobile"/></Grid>
                <Grid item xs={6}><TextInput label="resources.restaurants.fields.website" fullWidth
                                             source="website"/></Grid>
            </Grid>

            <Grid container spacing={2} fullWidth>

                <Grid item xs={6}><TextInput label="resources.restaurants.fields.address.address" fullWidth
                                             source="address.address"/> </Grid>
                <Grid item xs={6}><TextInput label="resources.restaurants.fields.address.city" fullWidth
                                             source="address.city"/></Grid>
                <Grid item xs={6}><TextInput label="resources.restaurants.fields.address.state" fullWidth
                                             source="address.state"/></Grid>
                <Grid item xs={6}><TextInput label="resources.restaurants.fields.address.country" fullWidth
                                             source="address.country"/></Grid>
                <Grid item xs={6}><TextInput label="resources.restaurants.fields.address.lat" fullWidth
                                             source="address.lat"/></Grid>
                <Grid item xs={6}><TextInput label="resources.restaurants.fields.address.lng" fullWidth
                                             source="address.lng"/></Grid>
            </Grid>

            <Grid container spacing={2} fullWidth>
                <Grid item xs={6}><TextInput label="resources.restaurants.fields.social.facebook" fullWidth
                                             source="social.facebook"/> </Grid>
                <Grid item xs={6}><TextInput label="resources.restaurants.fields.social.instagram" fullWidth
                                             source="social.instagram"/></Grid>
                <Grid item xs={6}><TextInput label="resources.restaurants.fields.social.twitter" fullWidth
                                             source="social.twitter"/></Grid>
            </Grid>


            <ImageInput source="logo" accept="image/*" fullWidth>
                <ImageField source="formats.small.url" title="title"/>
            </ImageInput>


            <ReferenceArrayInput label="resources.restaurants.fields.managers" source="managers" reference="users"
                                 fullWidth>
                <AutocompleteArrayInput optionText="username">
                    <ChipField source="username"/>
                </AutocompleteArrayInput>
            </ReferenceArrayInput>

            <ArrayInput label="resources.restaurants.fields.slots" source="slots">
                <SimpleFormIterator>
                    <SelectInput label="resources.restaurants.fields.day" source="day" fullWidth choices={[
                        {id: "working_days", name: "resources.restaurants.fields.days.workingDays"},
                        {id: "weekend", name: "resources.restaurants.fields.days.weekend"},
                        {id: "sunday", name: "resources.restaurants.fields.days.sunday"},
                        {id: "monday", name: "resources.restaurants.fields.days.monday"},
                        {id: "tuesday", name: "resources.restaurants.fields.days.tuesday"},
                        {id: "wednesday", name: "resources.restaurants.fields.days.wednesday"},
                        {id: "thursday", name: "resources.restaurants.fields.days.thursday"},
                        {id: "friday", name: "resources.restaurants.fields.days.friday"},
                        {id: "saturday", name: "resources.restaurants.fields.days.saturday"}
                    ]}/>
                    <ArrayInput label="resources.restaurants.fields.timeSlots" source="slots">
                        <SimpleFormIterator className={classes.flexRow}>
                            <TimeInput
                                source="open"
                                label={translate("resources.restaurants.fields.timeSlot.open")}
                                options={{
                                    format: 'HH:mm:ss',
                                    ampm: false,
                                }}
                                providerOptions={{
                                    utils: DateFnsUtils,
                                    locale: locale === 'pt' ? pt : enUS
                                }}
                                fullWidth
                            />
                            <TimeInput
                                source="close"
                                label={translate("resources.restaurants.fields.timeSlot.close")}
                                options={{
                                    format: 'HH:mm:ss',
                                    ampm: false,
                                }}
                                providerOptions={{
                                    utils: DateFnsUtils,
                                    locale: locale === 'pt' ? pt : enUS
                                }}
                                fullWidth
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleFormIterator>
            </ArrayInput>

            <ArrayInput label="resources.restaurants.fields.tags" source="tags">
                <SimpleFormIterator>
                    <TextInput label="resources.restaurants.fields.tag" source="tag" fullWidth/>
                </SimpleFormIterator>
            </ArrayInput>

            <div className={classes.amenities}>
                <label className="MuiFormLabel-root">{translate('resources.restaurants.fields.amenities.title')}</label>
                <Grid container spacing={1} fullWidth>
                    <Grid item xs={4}>
                        <BooleanInput
                            label="resources.restaurants.fields.amenities.airConditioning"
                            source="amenities.airConditioning"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BooleanInput
                            label="resources.restaurants.fields.amenities.creditCard"
                            source="amenities.creditCard"
                        />
                    </Grid>
                    <Grid item xs={4}> <BooleanInput label="resources.restaurants.fields.amenities.driveThru"
                                                     source="amenities.driveThru"/> </Grid>
                    <Grid item xs={4}> <BooleanInput label="resources.restaurants.fields.amenities.freeWifi"
                                                     source="amenities.freeWifi"/> </Grid>
                    <Grid item xs={4}> <BooleanInput label="resources.restaurants.fields.amenities.outdoorSeating"
                                                     source="amenities.outdoorSeating"/> </Grid>
                    <Grid item xs={4}> <BooleanInput label="resources.restaurants.fields.amenities.parking"
                                                     source="amenities.parking"/> </Grid>
                    <Grid item xs={4}> <BooleanInput label="resources.restaurants.fields.amenities.smokingArea"
                                                     source="amenities.smokingArea"/> </Grid>
                    <Grid item xs={4}> <BooleanInput label="resources.restaurants.fields.amenities.takeAway"
                                                     source="amenities.takeAway"/> </Grid>
                    <Grid item xs={4}> <BooleanInput label="resources.restaurants.fields.amenities.tv"
                                                     source="amenities.tv"/> </Grid>
                    <Grid item xs={4}> <BooleanInput label="resources.restaurants.fields.amenities.waiterService"
                                                     source="amenities.waiterService"/> </Grid>
                    <Grid item xs={4}> <BooleanInput label="resources.restaurants.fields.amenities.wheelchairAccess"
                                                     source="amenities.wheelchairAccess"/> </Grid>
                    <Grid item xs={4}>
                        <SelectInput
                            label="resources.restaurants.fields.amenities.noise"
                            source="amenities.noise" fullWidth
                            choices={[
                                {id: 0, name: "resources.restaurants.fields.amenities.noiseLevel.none"},
                                {id: 1, name: "resources.restaurants.fields.amenities.noiseLevel.low"},
                                {id: 2, name: "resources.restaurants.fields.amenities.noiseLevel.medium"},
                                {id: 3, name: "resources.restaurants.fields.amenities.noiseLevel.high"}
                            ]}
                        />
                    </Grid>

                </Grid>
            </div>

        </SimpleForm>
    );
};

export default RestaurantProfileForm;
