import * as React from 'react';
import {cloneElement} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Button, CreateButton, ExportButton, TopToolbar} from 'react-admin';

import IconLink from '@material-ui/icons/Link';

import RecipeLinkModal from './RecipeLinkModal';


const RecipeListActions = (props) => {
    const history = useHistory();
    const {location} = history;

    const {restaurantId} = useParams();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <TopToolbar>
            {cloneElement(props.filters, {context: 'button'})}
            <CreateButton onClick={(e) => {
                e.preventDefault();
                history.push(`${location.pathname}/create`)
            }}/>
            <ExportButton/>

            <Button
                label="Link Recipe"
                onClick={handleOpen}
            >
                <IconLink/>
            </Button>

            <RecipeLinkModal
                restaurantId={restaurantId}
                open={open}
                onClose={() => setOpen(false)}
            />
        </TopToolbar>
    )
};

export default RecipeListActions;
